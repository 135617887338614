'use strict'

import { create } from './create'

/**
 * @namespace checkout
 */
export default function checkout () {
  return {
    create: create.bind(this)
  }
}
