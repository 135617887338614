'use strict'

import {
  get
} from './read.js'
/**
 * @namespace token
 */
export default function token () {
  return {
    get: get.bind(this)
  }
}
