'use strict'

import Cookies from 'js-cookie'
import StorefrontCore from '../../index.js'

export function requestInterceptor (config) {
  const env = StorefrontCore.getInstance().envAcronym
  const token = Cookies.get(`${env}mp_tk`)
  const request = verifyAuth(config, token)
  const pref = Cookies.get(`${env}mp_pref`)
    ? JSON.parse(atob(Cookies.get(`${env}mp_pref`)))
    : { timezone: 'E. South America Standard Time', language: 'pt-BR', theme: 'light' }

  request.headers['Accept-Language'] = pref.language
  request.headers['X-Theme'] = StorefrontCore.getInstance().theme
  request.headers.Timezone = pref.timezone
  return request
}

export function responseInterceptor (response) {
  return response.data
}

export function errorResponseInterceptor (error) {
  const baseResponse = {
    type: 'response',
    status: error.response && error.response.status,
    message: error.message,
    stack: error.stack,
    body: error.response && error.response.data
  }

  return Promise.reject(baseResponse)
}

export function errorRequestInterceptor (error) {
  const body = {
    type: 'request',
    status: error.request.status,
    message: error.message,
    stack: error.stack
  }
  return Promise.reject(body)
}

export function verifyAuth (config, token) {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}
