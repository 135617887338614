'use strict'

/**
 * @memberof category
 * @module removeCategory
 * @function
 * @returns {Promise} remove category
 */
export function remove (storeId, categoryId) {
  return this.request.delete(`${this.url}/bankingstore/v1/stores/${storeId}/categories/${categoryId}`)
}
