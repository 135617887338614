'use strict'

import {
  create
} from './create.js'
/**
 * @namespace payment
 */
export default function payment () {
  return {
    create: create.bind(this)
  }
}
