'use strict'
import token from './token'
import payment from './payment'
import utils from './utils'
import { request } from './support/http/request'

var CheckoutInstance = null

const domains = {
  mundipagg: '.mundipagg.com',
  pagarme: '.pagar.me'
}

export default class CheckoutCore {
  constructor ({ environment = 'production', theme = 'mundipagg' }) {
    this.token = token.bind(this)()
    this.utils = utils.bind(this)()
    this.payment = payment.bind(this)()
    this.request = request
    this._env = environment
    this._recaptcha = null
    this.theme = theme
    if (!CheckoutInstance) {
      CheckoutInstance = this
    }
    return CheckoutInstance
  }

  static getInstance () {
    if (!CheckoutInstance) {
      CheckoutInstance = new CheckoutCore({})
    }
    return CheckoutInstance
  }

  get domain () {
    return domains[this.theme] || '.mundipagg.com'
  }

  get checkout_api () { // eslint-disable-line
    let url
    switch (this._env) {
      case 'staging':
        url = 'https://stgapi.mundipagg.com/checkout/v1'
        break
      case 'production':
        url = 'https://api.mundipagg.com/checkout/v1'
        break
      case 'contingency':
        url = 'https://api.mundipagg.com/checkout/v1'
        break
      case 'development':
        url = 'https://devapi.mundipagg.com/checkout/v1'
        break
      default:
        url = 'https://stgapi.mundipagg.com/checkout/v1'
    }
    return url
  }

  get envAcronym () {
    let acronym
    switch (this._env) {
      case 'development':
      case 'local':
        acronym = 'dev_'
        break
      case 'staging':
        acronym = 'stg_'
        break
      case 'contingency':
      case 'production':
        acronym = ''
        break
      default:
        acronym = 'stg_'
    }
    return acronym
  }

  get maps_api () { // eslint-disable-line
    return 'https://api.mundipagg.com/maps/v1.0'
  }

  get utilities_api () { // eslint-disable-line
    return 'https://api.mundipagg.com/utilities/v2'
  }

  get recaptcha () {
    return this._recaptcha
  }

  set recaptcha (recaptcha) {
    this._recaptcha = recaptcha
  }

  paramSerializer (params = {}) {
    let queryParams = Object.entries(params)
    if (queryParams.length > 0) {
      queryParams = queryParams.reduce((prev, actual, index) => {
        return index === 0 ? prev + `${actual[0]}=${actual[1]}` : prev + `&${actual[0]}=${actual[1]}`
      }, '?')
    }
    return queryParams
  }
}
