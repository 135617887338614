'use strict'

/**
 * @memberof product
 * @module getAll
 * @function
 * @returns {Promise} get all products by storeId
 * doc: https://stgapi.mundipagg.com/bankingstore/v1/docs/index.html#operation/ListProducts
 */
export function getAll (storeId, params = {}) {
  params.page = params.page || 1
  params.size = params.size || 9
  const queryParams = this.paramSerializer(params)
  return this.request.get(`${this.url}/bankingstore/v1/stores/${storeId}/products${queryParams}`)
}

/**
 * @memberof product
 * @module getById
 * @function
 * @returns {Promise} get product by id
 * doc: https://stgapi.mundipagg.com/bankingstore/v1/docs/index.html#operation/GetProduct
 */
export function getById (storeId, productId) {
  return this.request.get(`${this.url}/bankingstore/v1/stores/${storeId}/products/${productId}`)
}
