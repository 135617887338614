'use strict'

import {
  getAddressByZipCode
} from './read.js'
/**
 * @namespace utils
 */
export default function utils () {
  return {
    getAddressByZipCode: getAddressByZipCode.bind(this)
  }
}
