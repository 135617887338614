'use strict'

import {
  getBrandByBin,
  setSimpleQuery
} from './read.js'
/**
 * @namespace utils
 */
export default function utils () {
  return {
    getBrandByBin: getBrandByBin.bind(this),
    setSimpleQuery: setSimpleQuery.bind(this)
  }
}
