'use strict'
import axios from 'axios'

/**
 * @memberof utils
 * @module getAddressByZipCode
 * @function
 * @param {string} zipcode - Zipcode number
 * @returns {Promise} Address info
 */
export function getAddressByZipCode (zipcode) {
  return axios.get(`${this.mapsApi}/BR/${zipcode}`)
    .then(response => Promise.resolve(response.data))
    .catch(e => Promise.reject(e.response.data))
}
