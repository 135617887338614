'use strict'
import category from './category'
import checkout from './checkout'
import product from './product'
import store from './store'
import utils from './utils'
import { request } from './support/http/request'

let StorefrontInstance = null

const domains = {
  mundipagg: '.mundipagg.com',
  pagarme: '.pagar.me'
}

export default class StorefrontCore {
  constructor ({ environment = 'production', theme = 'mundipagg' }) {
    this.category = category.bind(this)()
    this.checkout = checkout.bind(this)()
    this.product = product.bind(this)()
    this.store = store.bind(this)()
    this.utils = utils.bind(this)()
    this.request = request
    this.theme = theme
    this._env = environment

    if (!StorefrontInstance) {
      StorefrontInstance = this
    }
    return StorefrontInstance
  } 

  static getInstance () {
    if (!StorefrontInstance) {
      StorefrontInstance = new StorefrontCore()
    }
    return StorefrontInstance
  }

  get domain () {
    return domains[this.theme] || '.mundipagg.com'
  }

  get url () {
    let url
    switch (this._env) {
      case 'development':
      case 'staging':
        url = 'https://stgapi.mundipagg.com'
        break
      case 'production':
        url = 'https://api.mundipagg.com'
        break
      default:
        url = 'https://api.mundipagg.com'
    }

    return url
  }

  get storeUrl () {
    let url
    switch (this._env) {
      case 'development':
      case 'staging':
        url = 'https://store-api.stg.pagarme.net'
        break
      case 'production':
        url = 'https://store-api.prd.pagarme.net'
        break
      default:
        url = 'https://store-api.prd.pagarme.net'
    }

    return url
  }

  get mapsApi () {
    return 'https://api.mundipagg.com/maps/v1.0'
  }

  get envAcronym () {
    let acronym
    switch (this._env) {
      case 'development':
      case 'staging':
        acronym = 'stg_'
        break
      case 'production':
        acronym = ''
        break
      default:
        acronym = ''
    }

    return acronym
  }

  paramSerializer (params = {}) {
    let queryParams = Object.entries(params)
    if (queryParams.length > 0) {
      queryParams = queryParams.reduce((prev, actual, index) => {
        return index === 0 ? prev + `${actual[0]}=${actual[1]}` : prev + `&${actual[0]}=${actual[1]}`
      }, '?')
    }
    return queryParams
  }
}
