'use strict'

/**
 * @memberof product
 * @module update
 * @function
 * @returns {Promise} Update product
 * https://stgapi.mundipagg.com/bankingstore/v1/docs/index.html#operation/UpdateProduct
 */
export function update (storeId, productId, body) {
  return this.request.put(`${this.url}/bankingstore/v1/stores/${storeId}/products/${productId}`, body)
}
