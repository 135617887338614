'use strict'

/**
 * @memberof category
 * @module update
 * @function
 * @returns {Promise} Update category
 * https://stgapi.mundipagg.com/bankingstore/v1/docs/index.html#operation/UpdateCategory
 */
export function update (storeId, categoryId, body) {
  return this.request.put(`${this.url}/bankingstore/v1/stores/${storeId}/categories/${categoryId}`, body)
}
