'use strict'

/**
 * @memberof product
 * @module createProduct
 * @function
 * @param {Object} body
 * @returns {Promise}
 * https://stgapi.mundipagg.com/bankingstore/v1/docs/index.html#operation/CreateProduct
 */
export function create (storeId, body) {
  return this.request.post(`${this.url}/bankingstore/v1/stores/${storeId}/products`, body)
}
