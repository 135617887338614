'use strict'

/**
 * @memberof category
 * @module create
 * @function
 * @returns {Promise}
 * body -> https://stgapi.mundipagg.com/bankingstore/v1/docs/index.html#operation/CreateCategory
 */
export function create (storeId, body) {
  return this.request.post(`${this.url}/bankingstore/v1/stores/${storeId}/categories`, body)
}
