'use strict'

import { get } from './read'

/**
 * @namespace product
 */
export default function store () {
  return {
    get: get.bind(this)
  }
}
