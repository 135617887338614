'use strict'

/**
 * @memberof store
 * @module get
 * @function
 * @returns {Promise} Get store
 * doc: https://github.com/pagarme/store-api#get-storeid
 */
export function get (slugName) {
  return this.request.get(`${this.storeUrl}/${slugName}`)
}
