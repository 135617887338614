'use strict'

/**
 * @memberof category
 * @module getAll
 * @function
 * @returns {Promise} Get categories
 * doc: https://stgapi.mundipagg.com/bankingstore/v1/docs/index.html#operation/ListCategories
 */
export function getAll (storeId, params = {}) {
  const queryParams = this.paramSerializer(params)
  return this.request.get(`${this.url}/bankingstore/v1/stores/${storeId}/categories${queryParams}`)
}

/**
 * @memberof category
 * @module getById
 * @function
 * @returns {Promise} Get category by id
 * doc: https://stgapi.mundipagg.com/bankingstore/v1/docs/index.html#operation/GetCategory
 */
export function getById (storeId, categoryId) {
  return this.request.get(`${this.url}/bankingstore/v1/stores/${storeId}/categories/${categoryId}`)
}
