'use strict'

/**
 * @memberof product
 * @module remove
 * @function
 * @returns {Promise} remove product
 */
export function remove (storeId, productId) {
  return this.request.delete(`${this.url}/bankingstore/v1/stores/${storeId}/products/${productId}`)
}
