'use strict'

/**
 * @memberof checkout
 * @module create
 * @function
 * @param {Object} body .
 * @param {Array} body.items - Product list.
 * @param {Object} body.customer - custumer info
 * @returns {Promise}
 * https://stgapi.mundipagg.com/bankingstore/v1/docs/index.html#tag/Checkout
 */
export function create (storeId, body) {
  return this.request.post(`${this.url}/bankingstore/v1/stores/${storeId}/checkouts`, body)
}
