'use strict'

import { getAll, getById } from './read'

import { create } from './create'

import { update } from './update'

import { remove } from './delete'

/**
 * @namespace product
 */
export default function product () {
  return {
    getAll: getAll.bind(this),
    getById: getById.bind(this),
    create: create.bind(this),
    update: update.bind(this),
    remove: remove.bind(this)
  }
}
